<template>
  <v-card
    elevation="10"
    class="mx-2"
    heigth="590"
  >
    <v-toolbar
      dense
      dark
      primary-title
      color="red"
    >
      <v-toolbar-title>Mis Actividades</v-toolbar-title>
      <v-spacer />
      <v-btn
        dark
        text
        :to="{ name: 'actividad' }"
      >
        Nueva
        <v-icon>mdi-clipboard</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />

    <v-list
      class="overflow-y-auto"
      height="540"
      two-line
    >
      <v-list-item-group
        v-model="selected"
        active-class="primary--text"
      >
        <template>
          <v-list-item
            v-for="(item) in misactividades"
            :key="item.idactividad"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.nomcont" />

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.obs"
                />

                <v-list-item-subtitle v-text="item.fecha" />
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="item.hora" />
                <v-list-item-action-text v-text="item.horafin" />
                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  mdi-star-outline
                </v-icon>

                <v-icon
                  v-else
                  color="yellow darken-3"
                >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selected: [2],
    items: [],
    misactividades: [],
    fecha: new Date().toISOString().substr(0, 10)
  }),

  created() {
    this.consultar()
  },

  methods: {
    consultar() {
      // limpiar
      this.misactividades = []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)
      let payload = {
        fecha: this.fecha
      }

      this.$http
        .post('v2/mis.actividades', payload)
        .then(response => {
          console.log('response', response)
          let idactividad = 0
          if (response.data.error === null && response.data.result.length > 0) {
            this.misactividades = response.data.result
            // console.log(this.misactividades)

            this.misactividades.forEach(element => {
              element.idactividad = idactividad +1
              element.hora = element.fecha.substr(11, 5)
              element.fecha = element.fecha.substr(5, 5)
              element.horafin = element.fechafin.substr(11, 5)
            })

            // console.log("citas", this.misactividades)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    ver(item) {
      console.log('orden', item.idorden)
      this.verOrden(item.idordenes)
    }
  }
}
</script>
